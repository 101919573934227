<div class="h-full">
    <div class="px-20 py-10">
        <div class="flex items-center">
            <span class="text-navy rubik font-normal not-italic fs-16-px cursor-pointer focus:outline-none" routerLink="/admin/user-profile">Profile</span>
            <img src="assets/icon/karrow-right-icon.png" class="ml-2">
            <span class="text-navy rubik font-normal not-italic fs-16-px ml-2 cursor-pointer focus:outline-none" routerLink="/admin/edit-profile">Edit Profile</span>
            <img src="assets/icon/karrow-right-icon.png" class="ml-2">
            <span class="text-navy rubik font-semibold not-italic fs-16-px ml-2">Edit company details</span>
        </div>

        <div class="grid grid-cols-12 py-7">
            <div class="col-span-4">
                <div class="text-gray900 rubik font-semibold not-italic fs-20-px">
                    Personal information
                    <div class="text-gray rubik font-normal not-italic fs-14-px">Use a permanent address where you can receive mail.</div>
                </div>
            </div>

            <div class="col-span-8">
                <div class="bg-white rounded-md border border-gray-400">
                    <form [formGroup]="personalInfoForm">
                        <div class="px-5 py-3">
                            <div class="grid grid-cols-12 gap-5">
                                <div class="col-span-12">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Company name</label>
                                    <input type="text" formControlName="company_name" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': personalInfoForm.controls.company_name.touched && !personalInfoForm.controls.company_name.valid}">
                                    <ng-container *ngIf="personalInfoForm.controls.company_name.touched">
                                        <div class="text-red-700 mb-0 fs-14-px" *ngIf="personalInfoForm.controls.company_name.hasError('required')">*Company name is required.</div>
                                    </ng-container>
                                </div>

                                <div class="col-span-12">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Description</label>
                                    <textarea formControlName="textarea" class="w-full h-120-px custom-input" placeholder="Type here ..." autocomplete="off" [ngClass]="{'error  pr-30-px': personalInfoForm.controls.textarea.touched && !personalInfoForm.controls.textarea.valid}"></textarea>
                                    <ng-container *ngIf="personalInfoForm.controls.textarea.touched">
                                        <div class="text-red-700 mb-0 fs-14-px" *ngIf="personalInfoForm.controls.textarea.hasError('required')">*Description is required.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-12">
                                    <label class="text-navy rbuik font-normal not-italic fs-14-px">Sector</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_dropdown" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5">{{ limit_value ? limit_value : 'Dropdown' }}</span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_dropdown="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300 ">
                                            <strong>Dropdown</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300 ">
                                                <strong>Dropdown1</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="col-span-12">
                                    <label class="text-navy rbuik font-normal not-italic fs-14-px">Member since</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_member_since" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5"><i class="far fa-calendar"></i><span class="ml-2">{{ member_since_value ? member_since_value : 'June 12, 1970' }}</span></span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_member_since="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300 ">
                                            <strong>June 12, 1970</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300 ">
                                                <strong>June 13, 1970</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                        <div class="bg-save px-5 py-5">
                            <button class="custom-btn btn-blue rubik font-medium not-italic sfs-14-px px-5 py-2 ml-auto">Save</button>
                        </div>
                    </form>
                </div>
            </div>

            <hr class="w-full col-span-12 mt-5 border">

            <div class="col-span-4 mt-5">
                <div class="text-gray900 rubik font-semibold not-italic fs-20-px">
                    Network information
                    <div class="text-gray rubik font-normal not-italic fs-14-px">Your network and membership status.</div>
                </div>
            </div>

            <div class="col-span-8 mt-5">
                <div class="bg-white rounded-md border border-gray-400">
                    <form [formGroup]="networkInfoForm">
                        <div class="px-5 py-3">
                            <div class="grid grid-cols-12 gap-5">
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Network</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_electricity" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5">{{ network_value ? network_value : 'Electricity' }}</span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_electricity="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedElectricity(null)">
                                            <strong>Electricity</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base" *ngFor="let electricity_list of electricity">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedElectricity(item.name)">
                                                <strong>{{electricity_list.name}}</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Membership status</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_membership" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5">{{ membership_value ? membership_value : 'Member' }}</span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_membership="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300">
                                            <strong>Member</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300">
                                                <strong>Member</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <hr class="w-full col-span-12 mt-5 border">

            <div class="col-span-4 mt-5">
                <div class="text-gray900 rubik font-semibold not-italic fs-20-px">
                    Company address
                    <div class="text-gray rubik font-normal not-italic fs-14-px">Where else can people contact you.</div>
                </div>
            </div>

            <div class="col-span-8 mt-5">
                <div class="bg-white rounded-md border border-gray-400">
                    <form [formGroup]="companyAddressForm">
                        <div class="px-5 py-3">
                            <div class="grid grid-cols-12 gap-5">
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Country</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_country" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5">{{ country_value ? country_value : 'All' }}</span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_country="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedStatus(null)">
                                            <strong>All</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base" *ngFor="let country of country_list">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedStatus(item.name)">
                                                <strong>{{country.name}}</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">State/Province</label>
                                    <div #t2="matMenuTrigger" #select2 [matMenuTriggerFor]="select_state" class="border border-gray-400 rounded-md py-1 pl-0 pr-1 flex justify-between items-center h-50-px cursor-pointer">
                                        <span class="barlow text-black text-base font-lg p-5">{{ state_value ? state_value : 'All' }}</span>
                                        <i class="fas fa-caret-down"></i>
                                    </div>
                                    <mat-menu class="w-full sports-menu" #select_state="matMenu">
                                        <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedState(null)">
                                            <strong>All</strong>
                                        </button>
                                        <div class="w-full barlow relative flex items-center justify-end text-base" *ngFor="let state of state_province_list">
                                            <button mat-menu-item class="tracking-wide focus:outline-none barlow hover:text-teal-800 hover:bg-teal-300" (click)="clickedState(item.name)">
                                                <strong>{{state.name}}</strong> 
                                            </button>
                                        </div>
                                    </mat-menu>
                                </div>
                                <div class="col-span-12">
                                    <p class="text-navy rubik font-normal not-italic fs-14-px">Zipcode</p>
                                    <input type="text" formControlName="zipcode" class="custom-input h-12 w-393-px text-lg mt-2" (keypress)="keyPressNumbers($event)" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': companyAddressForm.controls.zipcode.touched && !companyAddressForm.controls.zipcode.valid}">
                                    <ng-container *ngIf="companyAddressForm.controls.zipcode.touched">
                                        <div class="text-red-700 mb-0 fs-14-px" *ngIf="companyAddressForm.controls.zipcode.hasError('required')">*Zipcode is required.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-12">
                                    <p class="text-navy rubik font-normal not-italic fs-14-px">Company main address</p>
                                    <input type="text" formControlName="company_main_address" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': companyAddressForm.controls.company_main_address.touched && !companyAddressForm.controls.company_main_address.valid}">
                                    <ng-container *ngIf="companyAddressForm.controls.company_main_address.touched">
                                        <div class="text-red-700 mb-0 fs-14-px" *ngIf="companyAddressForm.controls.company_main_address.hasError('required')">*Company main address is required.</div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="bg-save px-5 py-5">
                            <button class="custom-btn btn-blue rubik font-medium not-italic sfs-14-px px-5 py-2 ml-auto">Save</button>
                        </div>
                    </form>
                </div>
            </div>

            <hr class="w-full col-span-12 mt-5 border">

            <div class="col-span-4 mt-5">
                <div class="text-gray900 rubik font-semibold not-italic fs-20-px">
                    Contact information
                    <div class="text-gray rubik font-normal not-italic fs-14-px">Where else can people contact you.</div>
                </div>
            </div>

            <div class="col-span-8 mt-5">
                <div class="bg-white rounded-md border border-gray-400">
                    <form [formGroup]="contactInfoForm">
                        <div class="px-5 py-3">
                            <div class="grid grid-cols-12 gap-5">
                                <div class="col-span-6 relative">
                                    <p class="text-navy rubik font-normal not-italic fs-14-px">Website</p>
                                    <img src="assets/icon/web-icon.png" class="absolute left-0 top-8">
                                    <div class="ml-12">
                                        <input type="text" formControlName="website" class="custom-input h-12 w-full text-lg mt-3" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.website.touched && !contactInfoForm.controls.website.valid}">
                                        <ng-container *ngIf="contactInfoForm.controls.website.touched">
                                            <div class="text-red-700 mb-0 -ml-12 fs-14-px" *ngIf="contactInfoForm.controls.website.hasError('required')">*Website is required.</div>
                                            <div class="text-red-700 mb-0 -ml-12 fs-14-px" *ngIf="contactInfoForm.controls.website.hasError('pattern')">*Website link is invalid.</div>
                                        </ng-container>
                                    </div>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Telephone</label>
                                    <input 
                                        type="number" 
                                        onkeydown="return event.keyCode !== 69 && event.keyCode !== 190" 
                                        formControlName="mobile" 
                                        class="custom-input h-12 w-full text-lg mt-2" 
                                        (keypress)="keyPressNumbers($event)" 
                                        autocomplete="off" 
                                        [ngClass]="{'error  pr-30-px': contactInfoForm.controls.mobile.touched && !contactInfoForm.controls.mobile.valid}"
                                    >
                                    <ng-container *ngIf="contactInfoForm.controls.mobile.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.mobile.hasError('required')">*Telephone is required.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-12">
                                    <label class="text-navy rubik font-semibold not-italic fs-14-px">Social Links</label>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Linkedin</label>
                                    <input type="text" formControlName="linkedin" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.linkedin.touched && !contactInfoForm.controls.linkedin.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.linkedin.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.linkedin.hasError('required')">*Linkedin is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.linkedin.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Facebook</label>
                                    <input type="text" formControlName="facebook" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.facebook.touched && !contactInfoForm.controls.facebook.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.facebook.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.facebook.hasError('required')">*Facebook is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.facebook.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Twitter</label>
                                    <input type="text" formControlName="twitter" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.twitter.touched && !contactInfoForm.controls.twitter.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.twitter.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.twitter.hasError('required')">*Twitter is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.twitter.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Whatsapp</label>
                                    <input type="text" formControlName="whatspp" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.whatspp.touched && !contactInfoForm.controls.whatspp.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.whatspp.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.whatspp.hasError('required')">*Whatsapp is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.whatspp.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Wechat</label>
                                    <input type="text" formControlName="wechat" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.wechat.touched && !contactInfoForm.controls.wechat.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.wechat.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.wechat.hasError('required')">*Wechat is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.wechat.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                                <div class="col-span-6">
                                    <label class="text-navy rubik font-normal not-italic fs-14-px">Kakao</label>
                                    <input type="text" formControlName="kakao" class="custom-input h-12 w-full text-lg mt-2" maxlength="70" autocomplete="off" [ngClass]="{'error  pr-30-px': contactInfoForm.controls.kakao.touched && !contactInfoForm.controls.kakao.valid}">
                                    <ng-container *ngIf="contactInfoForm.controls.kakao.touched">
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.kakao.hasError('required')">*Kakao is required.</div>
                                        <div class="text-red-700 mb-0  fs-14-px" *ngIf="contactInfoForm.controls.kakao.hasError('pattern')">*Link is invalid.</div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                        <div class="bg-save px-5 py-5">
                            <button class="custom-btn btn-blue rubik font-medium not-italic sfs-14-px px-5 py-2 ml-auto">Save</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>