<!-- REPORT AS NO SHOW MODAL -->
<ngx-smart-modal #reportNoShow identifier="reportNoShow" customClass="nsm-centered" [dismissable]="false" [closable]="false"  (onOpen)="getData()" (onAnyCloseEvent)="reset()">
    <div *ngIf="meeting && !meeting['no_show']" class="bg-white rounded-md w-450-px rubik ">
        <div class="px-5 py-2">
            <div class="flex items-center default-text-color">
                <span class="flex items-center text-navy rubik font-semibold fs-16-px"> Report as no show </span> 
                <svg (click)="closeReportNoShow()" class="cursor-pointer ml-auto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6E6E6E"/>
                </svg>
            </div>
        </div>
        <hr class="w-full">
        <div class="relative px-5 py-3">
            <img src="assets/images/bg-report_show.png" class="absolute top-0 right-0">
            <div class="default-text-color rubik font-normal fs-16-px not-italic w-350-px">
                Are you sure you want to report this delegate as no show? This will notify the admin and will be validated first before approval.
            </div>
            <div class="flex items-center justify-end space-x-3 py-3 mt-2">
                <button (click)="closeReportNoShow()" class="text-main-gray rubik font-medium px-5 py-1 not-italic fs-14-px cursor-pointer focus:outline-none">Cancel</button>
                <button (click)="reportAsNoShow()" class="custom-btn btn-blue rubik font-medium not-italic fs-14-px px-6 py-3 focus:outline-none"> Report </button>
            </div>
        </div>
    </div>

    <div *ngIf="meeting && meeting['no_show']" class="bg-white rounded-md w-450-px rubik ">
        <div class="px-5 py-2">
            <div class="flex items-center default-text-color">
                <span class="flex items-center text-navy rubik font-semibold fs-16-px"> Report as no show </span> 
                <svg (click)="closeReportNoShow()" class="cursor-pointer ml-auto" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6E6E6E"/>
                </svg>
            </div>
        </div>
        <hr class="w-full">
        <div class="relative px-5 py-3">
            <img src="assets/images/bg-report_show.png" class="absolute top-0 right-0">
            <div class="default-text-color rubik font-normal fs-16-px not-italic w-350-px">
                Are you sure you want to cancel the report for this delegate?
            </div>
            <div class="flex items-center justify-end space-x-3 py-3 mt-2">
                <button (click)="closeReportNoShow()" class="text-main-gray rubik font-medium px-5 py-1 not-italic fs-14-px cursor-pointer focus:outline-none">Cancel</button>
                <button (click)="removeNoshowReport()" class="custom-btn btn-blue rubik font-medium not-italic fs-14-px px-6 py-3 focus:outline-none"> Cancel Report </button>
            </div>
        </div>
    </div>
</ngx-smart-modal>