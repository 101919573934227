<ngx-smart-modal #deleteModal identifier="deleteModal" [dismissable]="false" [closable]="false">
    <div class="flex flex-col items-center justify-center w-full mt-10 mb-10">
        <p class="text-lg text-gray">Are you sure, you want to delete this?</p>
        <div class="flex justify-between mt-5">
            <div class="flex items-center">
                <button (click)="close()" class="focus:outline-none rounded text-white py-1 px-5 bg-blue-500 hover:bg-blue-700 ...">Yes</button>
            </div>
            <div class="flex items-center ml-2">
                <button (click)="close()" class="focus:outline-none rounded text-white py-1 px-5 bg-pink-500 hover:bg-pink-700 ...">No</button>
            </div>
        </div>
    </div>
</ngx-smart-modal>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="large" color="#934d4d" type="square-jelly-box">
</ngx-spinner>