<div class="py-5 border border-gray-300 bg-white grid-f ">
    <div class="flex justify-between">
        <div>
            <div class="text-main-gray rubik font-normal not-italic fs-14-px">2021 Copyright @ Face2Face Scheduler All Rights Reserved</div>
        </div>
        
        <div class="flex justify-end space-x-12">
            <div>
                <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/f2fAboutScheduler">About Face2face</div>
            </div>
            <div>
                <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/terms-condition">Terms of Services</div>
            </div>
            <div>
                <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/privacy-policy">Privacy Policy</div>
            </div>
        </div>
    </div>
</div>


<!-- <div class="flex justify-between py-5 border border-gray-300 bg-white grid-f">
    <div>
        <div class="text-main-gray rubik font-normal not-italic fs-14-px">2021 Copyright @ Face2Face Scheduler all rights reserved</div>
    </div>
    <div>
        <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/f2fAboutScheduler">About Face2face</div>
    </div>
    <div>
        <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/terms-condition">Terms of Services</div>
    </div>
    <div>
        <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" routerLink="/privacy-policy">Privacy Policy</div>
    </div>
</div> -->