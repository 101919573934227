<ngx-smart-modal #forgotpassModal identifier="forgotpassModal" customClass="nsm-centered forgot">
    <!--     (onOpen)="getConfirmDetail()"
        (onClose)="reset()"
        (onAnyCloseEvent)="reset()" -->
    <div class="minw-280-px maxw-448-px rubik space-y-4 pt-3">
        <div class="text-2xl font-semibold default-text-color">Forgot Password</div>
        <div>
            Please enter the email you registered and we’ll send you a link to reset your password.
        </div>
        <div>
            <input type="text" class="custom-input w-full" name="" id="">
        </div>
        <div class="flex justify-end">
            <button class="custom-btn btn-blue px-6 py-3">
                    Submit
                </button>
        </div>
    </div>
</ngx-smart-modal>