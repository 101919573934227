<!-- <div class="w-screen h-screen bg-gray-card dark:bg-gray-600 overflow-hidden">
    <div class="container h-full mx-auto">
        <div class="flex flex-col justify-center items-center h-full text-center text-red-600 text-5xl font-mono">
            <div class="absolute top-4 right-6">
                <div class="flex items-center">
                    <mat-slide-toggle  (toggleChange)="toggleChanged()" #toggle></mat-slide-toggle>
                    <span class="dark:text-gray-300 text-lg ml-2">Dark Mode</span>
                </div>
            </div>
            <img src="../assets/images/logos/angular-tailwind-logo.png" alt="">
            <div class="block font-extrabold font-serif text-center mt-4 dark:text-gray-300 text-3xl">
               + MATERIAL 
            </div>
        </div>
    </div>
</div>

 -->
<!-- Do not delete anything after this line -->
<router-outlet></router-outlet>


<!-- // Modal -->
<app-edit-confirm></app-edit-confirm>
<app-delete-confirm></app-delete-confirm>
<app-save-modal></app-save-modal>
<!-- <app-photo-modal></app-photo-modal> -->
<app-forgot-modal></app-forgot-modal>
<app-social-links></app-social-links>
<app-image-cropper-modal></app-image-cropper-modal>
<app-schedule-meeting></app-schedule-meeting>
<app-view-meeting-detail></app-view-meeting-detail>
<app-find-delegate-meeting-schedule></app-find-delegate-meeting-schedule>
<app-report-no-show></app-report-no-show>
<app-loader-modal></app-loader-modal>
<app-rechedule-modal></app-rechedule-modal>
<app-request-cancel-meeting></app-request-cancel-meeting>
<!-- <app-messaging-modal-search></app-messaging-modal-search> -->

