<div class="flex items-start space-x-3 mt-8">
    <div class="h-30-px w-30-px flex-grow-0 flex-shrink-0" *ngIf="message.sender != 'delegate1'">
        <img src="assets/empty_states/profile_avatar4.png" class="object-cover w-full" alt="">
    </div>
    <div class="flex flex-col overflow-wrap: break-words w-full">
        <p class="maxw-520-px px-5 py-3 barlow  message-card-shadow bg-white text-lg leading-6  {{ message.sender == 'delegate1' ? 'self-end ml-auto mine' : 'others self-start'}}">
            <!-- [innerHTML]="data.content" {{ user.id == data.sender_id ? 'self self-end ml-auto text-white' : 'text-gray-600 others self-start'}}-->
            {{ message.content }}
        </p>
    </div>
</div>