<div class="h-full  py-8 custom-scroll overflow-auto">
    <div class="relative mb-20" *ngIf="!isLoading && privacyPolicy">
        <div class="px-20">
            <button class="border-2 border-gray-400 rounded-md flex items-center px-3 py-1 focus:outline-none" (click)="goBackLogin()">
                <img src="assets/icon/arrow-back-icon.png"> <span class="ml-2 rubik not-italic fs-14-px font-medium text-main-gray">Back to login</span>
            </button>
        </div>
        <img src="assets/images/bg-f2f.png" class="absolute left-8 top-8">
        <img src="assets/images/bg-f2f1.png" class="absolute right-0 top-3">
        <div class="bg-pad py-16">
            <div class="border-b-3 border-gray-300 bg-about rounded-md">
                <div class="text-center py-5">
                    <div class="text-white rubik font-semibold fs-32-px not-italic" [innerHTML]="privacyPolicy.title"></div>
                </div>
                <div class="hr-green py-1"></div>
                <div class="bg-white">
                    <div class="px-5 py-5 text-justify">
                        <div class="text-navy rubik font-normal not-italic fs-16-px" [innerHTML]="privacyPolicy.content">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>  

    <!-- Skeleton loader -->
    <div class="relative" *ngIf="isLoading">
        <img src="assets/images/bg-f2f.png" class="absolute left-8 top-8">
        <img src="assets/images/bg-f2f1.png" class="absolute right-0 top-3">
        <div class="bg-pad py-16">
            <div class="border-b-3 border-gray-300 bg-about rounded-md">
                <div class="flex justify-center py-5">
                    <div class="w-300-px h-20-px rounded-t-lg">
                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                    </div>
                </div>
                <div class="hr-green py-1"></div>
                <div class="bg-white">
                    <div class="px-5 py-2 text-justify space-y-2">
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="w-full h-15-px rounded-t-lg">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                    </div>
                </div>
            </div>
         </div>
    </div>

    <div class="grid grid-cols-12 px-10 py-5 bg-white absolute b-0 r-0 w-full border border-gray-300 mt-8">
        <div class="lg:col-span-6 col-span-12 md:text-center">
            <div class="text-main-gray rubik font-normal not-italic fs-14-px">2021 Copyright @ Face2Face Scheduler All Rights Reserved</div>
        </div>
        <div class="lg:col-span-2 col-span-12 md:text-center">
            <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" (click)="openAboutf2f()">About Face2face</div>
        </div>
        <div class="lg:col-span-2 col-span-12 md:text-center">
            <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" (click)="openTerms()">Terms of Services</div>
        </div>
        <div class="lg:col-span-2 col-span-12 md:text-center">
            <div class="text-footer rubik font-normal not-italic fs-14-px cursor-pointer" (click)="openPrivacy()">Privacy Policy</div>
        </div>
    </div>
</div>
