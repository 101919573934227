<div class="w-screen z-20 bg-white absolute">
    <app-header-dashboard></app-header-dashboard>
</div>
<div class="px-20 py-28">
    <div class="grid grid-cols-12 gap-5">
        <div class="col-span-12 lg:col-span-5">
            <div *ngIf="!isLoading" class="bg-white rounded-lg shadow-sm border border-gray-300">
                <img src="{{event.image_banner_url ? event.image_banner_url : 'assets/empty_states/no_event_profile.png'}}" class="object-cover rounded-t-lg h-237-px w-full" alt="">
                <div class="flex flex-col space-y-3 px-5 mt-5 mb-5">
                    <div class="rubik basic-text-color text-base font-semibold">
                        {{ event.name }}
                    </div>
                    <!-- 
                        If event is physical 
                    -->
                    <ng-container *ngIf="event.type == '1'">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 md:col-span-6 flex items-center space-x-2">
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2.25H13.25V0.75H11.75V2.25H4.25V0.75H2.75V2.25H2C1.175 2.25 0.5 2.925 0.5 3.75V15.75C0.5 16.575 1.175 17.25 2 17.25H14C14.825 17.25 15.5 16.575 15.5 15.75V3.75C15.5 2.925 14.825 2.25 14 2.25ZM14 15.75H2V7.5H14V15.75ZM2 6H14V3.75H2V6Z" fill="#6E6E6E"/>
                                </svg>
                                <div class="text-gray-500 text-sm">{{ event.event_date }}</div>   
                            </div>
                            <div class="col-span-12 md:col-span-6 flex items-center space-x-2">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14ZM7.25 4.25H8.375V8.15L11.75 10.175L11.15 11.15L7.25 8.75V4.25Z" fill="#6E6E6E"/>
                                </svg>
                                <span class="text-gray-500 text-sm uppercase"> 
                                    <span> {{ event.start_time}} - {{ event.end_time}} </span>
                                </span>   
                            </div>
                        </div>
                        <div class="flex items-center space-x-2">
                            <svg width="12" height="16" viewBox="0 0 12 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M6 0.5C3.0975 0.5 0.75 2.8475 0.75 5.75C0.75 9.6875 6 15.5 6 15.5C6 15.5 11.25 9.6875 11.25 5.75C11.25 2.8475 8.9025 0.5 6 0.5ZM2.25 5.75C2.25 3.68 3.93 2 6 2C8.07 2 9.75 3.68 9.75 5.75C9.75 7.91 7.59 11.1425 6 13.16C4.44 11.1575 2.25 7.8875 2.25 5.75ZM4.125 5.75C4.125 4.71447 4.96447 3.875 6 3.875C6.66987 3.875 7.28886 4.23237 7.6238 4.8125C7.95873 5.39263 7.95873 6.10737 7.6238 6.6875C7.28886 7.26763 6.66987 7.625 6 7.625C4.96447 7.625 4.125 6.78553 4.125 5.75Z" fill="#6E6E6E"/>
                            </svg>
                            <span class="text-gray-500 text-sm"> 
                                <span> {{ event.event_address }} </span>
                            </span>   
                        </div>
                    </ng-container>
                    
                    <!-- 
                        If event is virtual 
                    -->
                    <ng-container *ngIf="event.type == '2'">
                        <div class="grid grid-cols-12">
                            <div class="col-span-12 md:col-span-6 flex items-center space-x-2">
                                <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M14 2.25H13.25V0.75H11.75V2.25H4.25V0.75H2.75V2.25H2C1.175 2.25 0.5 2.925 0.5 3.75V15.75C0.5 16.575 1.175 17.25 2 17.25H14C14.825 17.25 15.5 16.575 15.5 15.75V3.75C15.5 2.925 14.825 2.25 14 2.25ZM14 15.75H2V7.5H14V15.75ZM2 6H14V3.75H2V6Z" fill="#6E6E6E"/>
                                </svg>
                                <div class="text-gray-500 text-sm">{{ event.event_date }}</div>   
                            </div>
                        </div>
                        <div class="flex items-center space-x-2">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 0.5C3.875 0.5 0.5 3.875 0.5 8C0.5 12.125 3.875 15.5 8 15.5C12.125 15.5 15.5 12.125 15.5 8C15.5 3.875 12.125 0.5 8 0.5ZM8 14C4.6925 14 2 11.3075 2 8C2 4.6925 4.6925 2 8 2C11.3075 2 14 4.6925 14 8C14 11.3075 11.3075 14 8 14ZM7.25 4.25H8.375V8.15L11.75 10.175L11.15 11.15L7.25 8.75V4.25Z" fill="#6E6E6E"/>
                            </svg>
                            <span class="text-gray-500 text-sm uppercase"> 
                                <span> {{ event.start_time }} - {{ event.end_time }} </span>
                                <span class="capitalize">{{ timezone_trimmed }}</span>  
                            </span>    
                        </div>
                    </ng-container>
                </div>
            </div>
            
            <ng-container *ngIf="isLoading">
                <div class="bg-white rounded-lg shadow-sm border border-gray-300">    
                    <div class="w-full h-237-px rounded-t-lg">
                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                    </div>
                    <div class="flex flex-col space-y-3 px-4 pb-2 mt-3 mb-2">
                        <div class="w-380-px h-15-px rounded-md">
                            <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                        </div>
                        <div class="flex items-center">
                            <div class="w-20-px h-15-px rounded-md">
                                <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                            </div>
                            <div class="ml-2 flex items-center space-x-3">
                                <div class="w-380-px h-15-px rounded-md">
                                    <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                </div>
                            </div>
                        </div>
                        <div class="flex items-center space-x-3">
                           <div class="flex items-center">
                                <div class="w-20-px h-15-px rounded-md">
                                    <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                </div> 
                                <div class="w-200-px h-15-px rounded-md ml-2">
                                    <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                </div> 
                           </div> 
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="leave-feedback-container bg-white shadow-sm rounded-md relative border border-gray-300 mt-8">
                <div class="flex items-center p-5">
                    <img class="-mt-6" src="assets/svg/feedback-icon.svg">
                    <div class="ml-7">
                        <div class="text-navy rubik font-semibold not-italic fs-16-px">Leave us a feedback</div>
                        <div class="text-darkblue rubik font-normal not-italic fs-14-px mt-1">Your feedback helps us improve our <br> events and mobile app, thank you!</div>
                        <div (click)="openFeedBackModal()" class="text-blue rubik font-normal not-italic fs-14-px flex items-center cursor-pointer mt-2">Share us your experience <span class="ml-5"><img src="assets/icon/arrow-blue-right-icon.png"></span></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-span-12 lg:col-span-7">
            <div class="bg-white rounded-lg shadow-sm " style="border: #A6CE39 1px solid;">
                <div class="grid p-4 grid-cols-12 gap-2 relative">
                    <img src="assets/icon/event-banner.svg" class="absolute right-3 top-0" alt="">
                    <div class="col-span-9 ml-4 flex items-center">
                        <!-- event virtual  -->
                        <ng-container *ngIf="event.type == '1'">
                            <img src="assets/svg/event_schedule.svg" class="w-72-px h-72-px mr-5" alt="">     
                            <div class="flex flex-col">
                                <div class="basic-text-color font-semibold mb-2">
                                    <!-- You have scheduled meetings. -->
                                    Look for meeting partners.
                                </div>
                                <div class="basic-text-color">
                                    <!-- You have meetings to attend to. <br>
                                    Don’t forget to look presentable on you meetings. -->
                                    You may search for delegates that you want to meet, and schedule a meeting with them.
                                </div>
                            </div>
                        </ng-container>

                        <!-- event physical -->
                        <ng-container *ngIf="event.type == '2'">
                            <img src="assets/svg/find_delegate.svg" class="w-72-px h-72-px mr-5" alt="">
                            <div class="flex flex-col">
                                <div class="basic-text-color font-semibold mb-2">
                                    <!-- You haven’t scheduled any meeting yet. -->
                                    Meet your partners personally.
                                </div>
                                <div class="basic-text-color">
                                    <!-- You may search delegates you want to meet <br> 
                                    then schedule a meeting with them. -->
                                    You have meetings to attend to. <br> 
                                    Don't forget to look presentable on your meetings.
                                </div>
                            </div>
                        </ng-container>
                    </div>
                    <!-- physical -->
                    <div class="col-span-3" *ngIf="event.type == '1'">
                        <div class="h-full flex items-end justify-end" style="color: #87AF1B;">
                            <div class="flex items-center cursor-pointer" (click)="browsePhysicalDelegates()">
                                <div>
                                    Browse delegates
                                </div>
                                <svg class="ml-4" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.294983 10.59L4.87498 6L0.294983 1.41L1.70498 0L7.70498 6L1.70498 12L0.294983 10.59Z" fill="#87AF1B"/>
                                </svg>                                
                            </div>
                        </div>
                    </div>
                    <!-- end physical -->
                    <!-- virtual -->
                    <div class="col-span-3" *ngIf="event.type == '2'">
                        <div class="h-full flex items-end justify-end" style="color: #87AF1B;">
                            <div class="flex items-center cursor-pointer" (click)="browseVirtualDelegates()">
                                <div>
                                    Browse delegates
                                </div>
                                <svg class="ml-4" width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.294983 10.59L4.87498 6L0.294983 1.41L1.70498 0L7.70498 6L1.70498 12L0.294983 10.59Z" fill="#87AF1B"/>
                                </svg>                                
                            </div>
                        </div>
                    </div>
                    <!-- end virtual -->
                </div>
            </div>
            <div class="bg-white rounded-lg shadow-sm border border-gray-300 mt-5">
                <div class="p-7">
                    <div *ngIf="event.description">
                        <label class="text-gray-500">Description</label>
                        <div *ngIf="!isLoading" [innerHTML]="event.description" class="default-text-color mt-2"> </div>
                        <ng-container *ngIf="isLoading">
                            <div class="flex flex-col mt-2 space-y-2">
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                                 <div class="w-full h-15-px rounded-md">
                                     <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                 </div>
                            </div>
                         </ng-container>
                    </div>
                    <div *ngIf="(event && event.type == '1') && (event && event.lat && event.long)" class="pt-7">
                        <!-- <label class="text-gray-500">Event Address:</label> -->
                    </div>
                </div>
                <div *ngIf="(event && event.type == '1') && (event && event.lat && event.long)">
                    <div class="event-address-map my-3 -mt-3" id="event-address-map">
                        <agm-map [latitude]="event.lat" [longitude]="event.long" [scrollwheel]="false" [zoom]="10" [mapDraggable]="false" [streetViewControl]="false">
                          <agm-marker [latitude]="event.lat" [longitude]="event.long"></agm-marker>
                        </agm-map>
                    </div>
                 
                </div>

                <div *ngIf="(event && event.type == '1') && (event && event.image_floor_plan_url)" class="p-7 pt-0 pb-0">
                    <label class="text-gray-500">Floor Plan:</label>
                </div>
                <div *ngIf="(event && event.type == '1') && (event && event.image_floor_plan_url)">
                    <img  [src]="event.image_floor_plan_url" class="object-cover w-full my-3" alt="">
                </div>
            
                <div class="h-45-px px-7 flex items-center space-x-3" style="background: #EEEEEE;">
                    <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0691 2.97328C12.9939 3.55309 13.6423 4.53424 13.7604 5.67314C14.1375 5.84935 14.5561 5.95069 14.9999 5.95069C16.62 5.95069 17.9331 4.63757 17.9331 3.01771C17.9331 1.39759 16.62 0.0844727 14.9999 0.0844727C13.3952 0.0849718 12.0936 1.37463 12.0691 2.97328ZM10.1475 8.97902C11.7676 8.97902 13.0807 7.66565 13.0807 6.04579C13.0807 4.42592 11.7674 3.1128 10.1475 3.1128C8.52764 3.1128 7.21378 4.42617 7.21378 6.04603C7.21378 7.6659 8.52764 8.97902 10.1475 8.97902ZM11.3917 9.17894H8.90278C6.8319 9.17894 5.14714 10.864 5.14714 12.9348V15.9786L5.15487 16.0263L5.36453 16.092C7.34082 16.7095 9.05778 16.9154 10.471 16.9154C13.2312 16.9154 14.8312 16.1284 14.9297 16.0782L15.1257 15.9791H15.1466V12.9348C15.1474 10.864 13.4626 9.17894 11.3917 9.17894ZM16.2446 6.15086H13.7749C13.7482 7.13901 13.3263 8.02881 12.6592 8.66877C14.4999 9.21613 15.8468 10.9231 15.8468 12.9393V13.8773C18.2853 13.788 19.6905 13.0968 19.7831 13.0504L19.979 12.9511H20V9.90626C20 7.83563 18.3152 6.15086 16.2446 6.15086ZM5.00062 5.95119C5.57444 5.95119 6.10832 5.78371 6.56059 5.49842C6.70436 4.5607 7.20704 3.74128 7.92512 3.18319C7.92812 3.12828 7.93336 3.07386 7.93336 3.01846C7.93336 1.39834 6.61999 0.0852214 5.00062 0.0852214C3.38026 0.0852214 2.06739 1.39834 2.06739 3.01846C2.06739 4.63782 3.38026 5.95119 5.00062 5.95119ZM7.63484 8.66877C6.97092 8.03206 6.55061 7.147 6.51991 6.16484C6.4283 6.1581 6.3377 6.15086 6.24435 6.15086H3.75565C1.68476 6.15086 0 7.83563 0 9.90626V12.9506L0.00773743 12.9975L0.217397 13.0636C1.80282 13.5586 3.21777 13.7867 4.44677 13.8586V12.9393C4.44727 10.9231 5.79359 9.21663 7.63484 8.66877Z" fill="#05172D"/>
                    </svg>
                    <div class="basic-text-color font-semibold">
                        Speakers
                    </div>
                </div>

               <ng-container *ngIf="!isLoading" >
                    <div *ngIf="speakers && speakers_total_count > 0" class="maxh-300-px custom-scroll overflow-y-auto pb-4" (scroll)="onScroll()">
                        <div class="border-bottom-gray pl-7 pr-7 pt-4 pb-4 grid grid-cols-12 gap-3 cursor-pointer" *ngFor="let speaker of speakers" (click) = 'viewSpeakerDetails(speaker)'>
                            <div class="col-span-1">
                                <img [src]="speaker.photo_url ? speaker.photo_url : ''" class="object-cover rounded-full w-40-px h-40-px" alt="">
                            </div>
                            <div class="col-span-11">
                                <div class="flex flex-col">
                                    <div class="default-text-color font-semibold">
                                        {{ speaker.company ? speaker.company : 'No Company' }}
                                    </div>
                                    <div class="default-text-color">
                                        {{ speaker.fullname ? speaker.fullname : 'No Fullname' }}
                                    </div>
                                    <div class="text-gray-500">
                                        {{ speaker.position ? speaker.position : 'No Position' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
               </ng-container>

               <!-- skeleton -->
                <ng-container *ngIf="isLoading">
                    <div class="grid grid-cols-12 gap-3 maxh-300-px custom-scroll px-7 py-5">
                        <ng-container *ngFor="let item of [1,2,3]">
                            <div class="col-span-1">
                                <div class="w-40-px h-40-px rounded-full">
                                    <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                </div>
                            </div>
                            <div class="col-span-11">
                                <div class="flex flex-col space-y-2">
                                    <div class="default-text-color font-semibold w-150-px h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                    </div>
                                    <div class="default-text-color w-160-px h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                    </div>
                                    <div class="text-gray-500 w-1800-px h-15-px">
                                        <app-loader [border_radius_class]="'rounded-lg'" [additional_class]=""></app-loader>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>


<!-- feedbackModal -->
<ngx-smart-modal #feedbackModal identifier="feedbackModal" customClass="nsm-centered" [dismissable]="false" [closable]="false">
    <div class="bg-white w-350-px rounded-lg relative">
        <div>
            <button (click)="closeFeedBackModal()">
                <svg  class="bg-close absolute top-5 right-5 z-50" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0  1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6E6E6E"/>
                </svg>
            </button>
            <img src="assets/images/bg-feedback.png" class="absolute top-0 right-0">
            <img src="assets/images/bg-feedback1.png" class="absolute top-0 left-0">
            <div class="flex justify-center">
                <img src="assets/images/Feedback.png">
            </div>
            <div  class="text-navy rubik font-semibold not-italic fs-16-px px-8 mt-8">  Leave us a feedback
                <div class="text-darkblue rubik font-normal not-italic fs-16-px">Your feedback helps us improve our <br> events and mobile app, thank you!</div>
            </div>
            <form [formGroup]="feedBackForm">
                <div class="px-8 mt-5">
                    <div class="text-navy rubik font-normal not-italic fs-14-px">How was your experience with F2F been?</div>
                    <textarea cols="30" rows="5" placeholder="Type here ..." class="custom-input w-full mt-2" formControlName="message" minlength="3" maxlength="200"  name="title" id="title" ></textarea>
                    <div *ngIf="feedBackForm.controls.message.value" class="counter text-main-gray rubik font-normal not-italic fs-12-px flex justify-end">
                        {{ feedBackForm.controls.message.value.length }}/200
                    </div>
                    <div class="flex justify-end space-x-5 py-5">
                        <button (click)="closeFeedBackModal()" class="text-main-gray rubik font-semibold not-italic fs-14-px">Cancel</button>
                        <button [disabled]="!feedBackForm.controls.message.value" [ngClass]="{'btn-gray': !feedBackForm.controls.message.value, 'btn-green-light': feedBackForm.controls.message.value}" (click)="savefeedBack()" class="focus:outline-none custom-btn rubik font-medium not-italic fs-14-px px-4 py-2">
                            <img src="assets/icon/send-icon.png"> 
                            <span class="ml-2">Send feedback</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</ngx-smart-modal>

<!-- Speaker Details Modal -->
<ngx-smart-modal #speaker_details identifier="speaker_details" customClass="nsm-centered" [dismissable]="false" [closable]="false" (onOpen)="getSpealkerDetails()" (onAnyCloseEvent)="resetSpeakerDetails()">
    <ng-container *ngIf = 'speakerDetails'>
        <div class="bg-white w-450-px rounded-lg py-5">
            <div class="text-black rubik font-semibold not-italic fs-16-px flex justify-between px-5">
                <div class="flex">
                    <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.0691 4.97279C12.9939 5.5526 13.6423 6.53375 13.7604 7.67265C14.1375 7.84887 14.5561 7.9502 14.9999 7.9502C16.62 7.9502 17.9331 6.63709 17.9331 5.01722C17.9331 3.3971 16.62 2.08398 14.9999 2.08398C13.3952 2.08448 12.0936 3.37414 12.0691 4.97279ZM10.1475 10.9785C11.7676 10.9785 13.0807 9.66516 13.0807 8.0453C13.0807 6.42543 11.7674 5.11231 10.1475 5.11231C8.52764 5.11231 7.21378 6.42568 7.21378 8.04555C7.21378 9.66541 8.52764 10.9785 10.1475 10.9785ZM11.3917 11.1785H8.90278C6.8319 11.1785 5.14714 12.8635 5.14714 14.9344V17.9782L5.15487 18.0258L5.36453 18.0915C7.34082 18.709 9.05778 18.9149 10.471 18.9149C13.2312 18.9149 14.8312 18.1279 14.9297 18.0777L15.1257 17.9787H15.1466V14.9344C15.1474 12.8635 13.4626 11.1785 11.3917 11.1785ZM16.2446 8.15038H13.7749C13.7482 9.13852 13.3263 10.0283 12.6592 10.6683C14.4999 11.2156 15.8468 12.9226 15.8468 14.9388V15.8768C18.2853 15.7875 19.6905 15.0963 19.7831 15.0499L19.979 14.9506H20V11.9058C20 9.83514 18.3152 8.15038 16.2446 8.15038ZM5.00062 7.9507C5.57444 7.9507 6.10832 7.78322 6.56059 7.49794C6.70436 6.56021 7.20704 5.74079 7.92512 5.1827C7.92812 5.12779 7.93336 5.07338 7.93336 5.01797C7.93336 3.39785 6.61999 2.08473 5.00062 2.08473C3.38026 2.08473 2.06739 3.39785 2.06739 5.01797C2.06739 6.63734 3.38026 7.9507 5.00062 7.9507ZM7.63484 10.6683C6.97092 10.0316 6.55061 9.14651 6.51991 8.16435C6.4283 8.15761 6.3377 8.15038 6.24435 8.15038H3.75565C1.68476 8.15038 0 9.83514 0 11.9058V14.9501L0.00773743 14.997L0.217397 15.0631C1.80282 15.5581 3.21777 15.7862 4.44677 15.8581V14.9388C4.44727 12.9226 5.79359 11.2161 7.63484 10.6683Z" fill="#05172D"/>
                    </svg> &nbsp;&nbsp;
                    About the Speaker
                </div>
                
                <svg (click)="closeSpeakerDetails()" class="cursor-pointer" width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z" fill="#6E6E6E"/>
                </svg>
            </div>

            <br>

            <div class="flex px-5">
                <div class="px-2">
                    <img [src]="speakerDetails.photo_url ? speakerDetails.photo_url : ''" class="object-cover rounded-full w-40-px h-40-px" alt="">
                </div>
                <div class="px-2">
                    <div class="flex flex-col">
                        <div class="default-text-color font-semibold">
                            {{ speakerDetails.company ? speakerDetails.company : 'No Company' }}
                        </div>
                        <div class="default-text-color">
                            {{ speakerDetails.fullname ? speakerDetails.fullname : 'No Fullname' }}
                        </div>
                        <div class="text-gray-500">
                            {{ speakerDetails.position ? speakerDetails.position : 'No Position' }}
                        </div>
                    </div>
                </div>

                
            </div>

            <br>

            <div class="px-5">
                <div class="px-2">
                    <div class="flex flex-col">
                        <div class="text-gray-500">
                           Description
                        </div>
                    </div>
                </div>

                <div class="px-2">
                    <div class="flex flex-col">
                        <div class="default-text-color">
                            {{ speakerDetails.description ? speakerDetails.description : 'No Description' }}
                        </div>
                    </div>
                </div>
            </div>
            

           
            
        </div>
    </ng-container>
</ngx-smart-modal>
