import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-forgot-modal',
  templateUrl: './forgot-modal.component.html',
  styleUrls: ['./forgot-modal.component.scss']
})
export class ForgotModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
