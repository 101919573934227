<div class="w-screen absolute z-50" style="background: #FFFFFF;">
    <app-header-dashboard></app-header-dashboard>
</div>
<div class="w-screen absolute" style="margin-top: 70px; z-index: 20;">
    <app-archive-header></app-archive-header>
</div>
<div class="h-full">
    <div class="relative py-20">
        <img src="assets/images/bg-faqs.png" class="absolute left-0 top-12">
        <img src="assets/images/bg_faqs2.png" class="absolute right-0 top-12">
        <div class="px-20 py-16 relative">
            <div class="grid grid-cols-12 gap-5">
                <div class="col-span-8">
                    <div class="text-navy rubik font-semibold not-italic fs-32-px">How can we help you?</div>
                    <div class="relative mt-6" [formGroup]="searchForm">
                        <input type="text" class="custom-input w-full pl_search" placeholder="Search" formControlName="search" (keyup)="searchKey($event)">
                        <span (click)="searchList()" class="material-icons text-gray-400 fs-75-px mb-3 absolute top-3 left-3">
                            search
                        </span>
                    </div>
                    
                    <!-- Empty Search -->
                    <ng-container *ngIf="isFaqsEmpty">
                        <div class="flex justify-center mt-20">
                            <svg width="148" height="112" viewBox="0 0 148 112" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <ellipse cx="74" cy="106" rx="74" ry="6" fill="#E1E1E1"/>
                                <g clip-path="url(#clip0)">
                                <path d="M81.4268 38.4791C81.4268 24.6811 70.2595 13.4949 56.4849 13.4949C42.7102 13.4949 31.543 24.6811 31.543 38.4791C31.543 50.5587 40.1028 60.6452 51.4931 62.9649L59.0065 70.491C59.6927 71.1783 60.8763 70.6972 60.8763 69.7178V63.0336C72.5582 60.9544 81.4268 50.7649 81.4268 38.4791Z" fill="#CCCCCC"/>
                                <path d="M112.219 31.2106H81.771C80.4158 26.4853 77.7569 22.1723 74.0345 18.8388C68.3908 13.7698 61.1004 11.4501 53.5012 12.2921C41.3047 13.6324 31.5441 23.4954 30.309 35.7126C29.6228 42.3796 31.4583 48.995 35.438 54.2874C39.2634 59.3908 44.7355 62.8961 50.9109 64.2364L57.3951 70.7316C57.9441 71.2814 58.6645 71.5735 59.4021 71.5735C59.7624 71.5735 60.1398 71.5048 60.5 71.3502C61.5635 70.9034 62.2497 69.8724 62.2497 68.7212V64.1677C63.3133 63.9271 64.3596 63.6178 65.3717 63.257V63.4288C65.3717 66.6248 67.962 69.2195 71.1526 69.2195H80.8446L86.008 74.3916C86.4711 74.8555 87.0887 75.0961 87.7062 75.0961C88.015 75.0961 88.3238 75.0273 88.6325 74.907C89.5417 74.529 90.1249 73.6527 90.1249 72.6733V69.2023H112.219C115.41 69.2023 118 66.6077 118 63.4116V37.0013C118 33.8053 115.41 31.2106 112.219 31.2106ZM60.6372 61.6933C59.9854 61.8136 59.5051 62.3806 59.5051 63.0508V68.7383C59.5051 68.7727 59.5051 68.8071 59.4536 68.8243C59.4021 68.8414 59.385 68.8243 59.3507 68.8071L52.5749 62.0026C52.3862 61.8136 52.1288 61.6761 51.8715 61.6246C46.1936 60.4905 41.1503 57.3116 37.6509 52.655C34.0828 47.8953 32.4532 41.9844 33.0536 36.0047C33.5854 30.7295 35.9698 25.712 39.7608 21.8802C43.5519 18.0312 48.5265 15.6084 53.7928 15.0242C54.7019 14.9211 55.6111 14.8696 56.5203 14.8696C62.3355 14.8696 67.8248 16.9659 72.199 20.8836C77.1908 25.3512 80.0556 31.7777 80.0556 38.479C80.0384 49.9229 71.8903 59.6829 60.6372 61.6933ZM115.256 63.446C115.256 65.1127 113.9 66.4702 112.236 66.4702H88.7526C87.9978 66.4702 87.3803 67.0888 87.3803 67.8448V71.8657L82.3885 66.8654C82.1312 66.6077 81.7881 66.4702 81.4107 66.4702H71.1355C69.4715 66.4702 68.1164 65.1127 68.1164 63.446V62.1057C76.9164 57.7756 82.783 48.7201 82.783 38.479C82.783 36.9497 82.6458 35.4548 82.3885 33.9771H112.202C113.866 33.9771 115.221 35.3345 115.221 37.0013V63.446H115.256Z" fill="#666666"/>
                                <path d="M105.392 41.8985L91.7033 55.6105L85.3392 49.2356C84.8074 48.703 83.9326 48.703 83.4008 49.2356C82.869 49.7683 82.869 50.6446 83.4008 51.1773L90.7255 58.5145C91 58.7894 91.3431 58.9097 91.7033 58.9097C92.0636 58.9097 92.4066 58.7722 92.6811 58.5145L107.348 43.823C107.88 43.2903 107.88 42.414 107.348 41.8813C106.799 41.3658 105.924 41.3658 105.392 41.8985Z" fill="#666666"/>
                                <path d="M56.4856 48.8576C55.7308 48.8576 55.1133 49.4762 55.1133 50.2322C55.1133 50.9883 55.7308 51.6069 56.4856 51.6069C57.2404 51.6069 57.8579 50.9883 57.8579 50.2322C57.8579 49.4762 57.2404 48.8576 56.4856 48.8576Z" fill="#666666"/>
                                <path d="M56.4857 25.3512C51.6826 25.3512 47.7715 29.269 47.7715 34.0802C47.7715 34.8363 48.389 35.4548 49.1438 35.4548C49.8986 35.4548 50.5161 34.8363 50.5161 34.0802C50.5161 30.7811 53.1921 28.1177 56.4686 28.1177C58.0124 28.1177 59.5048 28.7535 60.6541 29.9047C61.8035 31.056 62.4382 32.5337 62.4382 34.0974C62.4382 36.7092 60.6884 39.0633 58.2011 39.8193C56.3485 40.3692 55.1134 42.0359 55.1134 43.9433V44.39C55.1134 45.1461 55.7309 45.7647 56.4857 45.7647C57.2405 45.7647 57.858 45.1461 57.858 44.39V43.9433C57.858 43.2559 58.304 42.6545 58.9902 42.4483C62.644 41.3486 65.1999 37.912 65.1999 34.0974C65.1999 31.812 64.2908 29.6298 62.6097 27.963C60.9458 26.2619 58.7672 25.3512 56.4857 25.3512Z" fill="#666666"/>
                                </g>
                                <defs>
                                <clipPath id="clip0">
                                <rect width="88" height="88" fill="white" transform="translate(30)"/>
                                </clipPath>
                                </defs>
                            </svg>  
                        </div>
                        <div class="text-center rubik text-main-gray not-italic mt-5">
                            <p class="font-semibold fs-20-px">{{searchForm.value.search ? 'No FAQs found' : 'No FAQs added'}}</p>
                            <p class="font-medium fs-16-px">{{searchForm.value.search ? 'No faqs on that specified word' : 'Video tutorials and answers to some frequently'}}</p>
                            <p class="font-medium fs-16-px">{{searchForm.value.search ? 'Please search another faqs' : 'asked questions may help a lot of delegates.'}}</p>
                        </div>
                    </ng-container>
                    
                    <!-- Skeleton loader -->
                    <ng-container *ngIf="isFaqsLoading">
                        <div class="flex flex-col space-y-5 mt-8">
                            <div class="w-full-px h-50-px">
                                <app-loader [border_radius_class]="'rounded'" [additional_class]=""></app-loader>
                            </div>
                            <div class="w-full-px h-50-px">
                                <app-loader [border_radius_class]="'rounded'" [additional_class]=""></app-loader>
                            </div>
                            <div class="w-full-px h-50-px">
                                <app-loader [border_radius_class]="'rounded'" [additional_class]=""></app-loader>
                            </div>
                            <div class="w-full-px h-50-px">
                                <app-loader [border_radius_class]="'rounded'" [additional_class]=""></app-loader>
                            </div>
                            <div class="w-full-px h-50-px">
                                <app-loader [border_radius_class]="'rounded'" [additional_class]=""></app-loader>
                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!isFaqsEmpty && !isFaqsLoading">
                        <div *ngFor="let faqs_list of newFaqs; let i= index;" class="bg-white border border-gray-300 rounded-md mt-5">
                            <div class="px-5 py-5">
                                <div class="flex items-center" >
                                    <div class="text-navy rubik font-semibold not-italic fs-16-px">
                                        {{faqs_list.question}}
                                    </div>
                                    <div class="ml-auto">
                                        <!-- open -->
                                        <svg *ngIf="!showFaqs && openedFaqs != i" (click)="openFaqs(i)" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V11H7V13H11V17H13V13H17V11H13V7H11ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z" fill="#6E6E6E"/>
                                        </svg> 
                                        <svg *ngIf="showFaqs && openedFaqs != i" (click)="openFaqs(i)" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM11 7V11H7V13H11V17H13V13H17V11H13V7H11ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12Z" fill="#6E6E6E"/>
                                        </svg> 

                                        <!-- close -->
                                        <svg *ngIf="showFaqs && openedFaqs == i" (click)="closeFaqs(i)" class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 16.41 7.59 20 12 20C16.41 20 20 16.41 20 12C20 7.59 16.41 4 12 4C7.59 4 4 7.59 4 12ZM7 11V13H17V11H7Z" fill="#3B67B2"/>
                                        </svg>                                   
                                    </div>
                                </div>
                                <ng-container *ngIf="showFaqs && openedFaqs == i">
                                    <div class="text-navy rubik  not-italic fs-16-px mt-2" [innerHTML]="faqs_list.answer">
                                    </div>
                                    <div class="py-3" *ngIf="faqs_list.youtubelink">
                                        <div [innerHTML]="faqs_list.youtubelink"></div>
                                    </div>
                                    <div class="rubik font-medium not-italic fs-16-px text-main-gray flex items-center mt-2">
                                        <span>Was this answer helpful?</span>
                                        <span class="ml-2 mr-2 cursor-pointer text-y btn-yes" (click)="voteFaqsYES(faqs_list.id)">Yes</span> or <span class="ml-2 cursor-pointer text-y btn-no" (click)="voteFaqsNO(faqs_list.id)">No</span>
                                    </div>
                                </ng-container>
                            
                            </div>
                        </div>
                    </ng-container>
                </div>

                <div class="col-span-4">
                    <div class="bg-white border border-gray-300 rounded-md">
                        <div class="relative">
                            <img src="assets/images/bg_faqs.png" class="absolute top-0 left-0">
                            <img src="assets/images/bg_faqs1.png" class="absolute top-0 right-0">
                            <img src="assets/images/Feedback.png" class="absolute top-5" style="left: 150px;">
                        </div>
                       <div class="px-8 mt-32">
                           <div class="text-navy rubik font-semibold not-italic fs-16-px">
                               Leave us a feedback
                           </div>
                           <div class="rubik font-normal not-italic fs-16-px">
                               <p class="text-darkbue">Your feedback helps us improve our</p>
                               <p class="text-darkbue">events and mobile app, thank you!</p>
                           </div>
                           <form [formGroup]="feedBackForm">
                                <div class="text-navy rubik font-normal not-italic fs-14-px mt-5 mb-2">
                                    How was your experience with F2F been?
                                    <textarea cols="30" rows="5" placeholder="Type here ..." class="custom-input w-full mt-2" formControlName="message" minlength="3" maxlength="200"  name="title" id="title" ></textarea>
                                    <div *ngIf="feedBackForm.controls.message.value" class="counter text-main-gray rubik font-normal not-italic fs-12-px flex justify-end">
                                        {{ feedBackForm.controls.message.value.length }}/200
                                    </div>
                                </div>
                                <div class="flex justify-end mb-5">
                                    <button [disabled]="!feedBackForm.controls.message.value" (click)="savefeedBack()" [ngClass]="{'btn-gray': !feedBackForm.controls.message.value, 'btn-green-light': feedBackForm.controls.message.value}" class="focus:outline-none custom-btn  rubik font-medium not-italic fs-14-px px-5 py-3">
                                        <img src="assets/icon/send-icon.png">
                                        <span class="ml-2">Send feedback</span>
                                    </button>
                                </div>
                           </form>
                       </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

